
import { Select } from '@chakra-ui/react';
import { locales } from 'src/locales/locales'
import navigateLocalized from 'src/locales/navigate'
import { usePageContext } from 'vike-react/usePageContext';
import React from 'react';

const LocalePicker = () => {
    const { locale: currentLocale } = usePageContext()

    return (
        <Select 
            size='sm'
            value={currentLocale}
            onChange={(event) => navigateLocalized(window.location.pathname, event.target.value)}
        >
            {locales.map((locale) => (
                <option key={locale} value={locale}>
                    {/* <Link locale={locale} href="/" key={locale} style={{ marginLeft: 7 }}> */}
                    {locale}
                    {/* </Link> */}
                </option>
            ))}
        </Select>
    )
}

export default LocalePicker;
